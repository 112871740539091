.pricing-page {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .pricing-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .pricing-card h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .pricing-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .pricing-card ul li {
    margin-bottom: 0.5rem;
  }
  
  .pricing-card button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pricing-card button:hover {
    background-color: #0056b3;
  }