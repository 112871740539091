.navigation-bar {
    background-color: #f8f9fa;
    padding: 1rem 2rem;
  }
  
  .navigation-bar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .navigation-bar ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .navigation-bar ul li a:hover {
    color: #007bff;
  }