.language-selector {
    display: flex;
    gap: 0.5rem;
  }
  
  .language-selector button {
    background-color: transparent;
    border: 1px solid #dee2e6;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .language-selector button:hover {
    background-color: #f8f9fa;
  }