.footer {
    background-color: #f8f9fa;
    padding: 2rem 0;
    margin-top: 2rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin-bottom: 1rem;
  }
  
  .footer-section h3 {
    margin-bottom: 0.5rem;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.25rem;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #dee2e6;
  }