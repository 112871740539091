.contact-page {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    min-height: 150px;
  }
  
  .contact-form button {
    padding: 0.5rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-success {
    text-align: center;
    color: #28a745;
  }