.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 60px; /* Adjust this value as needed */
  width: auto; /* This maintains the aspect ratio */
  margin-right: 1rem;
}

.logo h1 {
  font-size: 1.5rem;
  margin: 0;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .logo img {
    height: 40px; /* Smaller size for mobile devices */
  }
  
  .logo h1 {
    font-size: 1.2rem;
  }
}